<script>
/* eslint-disable no-unused-vars */
import onTableCellBtnClick from '@/controllers/onTableCellBtnClick.js'
import Render from './Render'
export default {
  render (h) {
    var vm = this
    const mRender = new Render(vm, h)
    const labels = vm.tableHeader.map(item => item.label)
    return h(
      'el-table',
      {
        class: 'table-wrap',
        ref: 'tb',
        attrs: {
          data: vm.tableData,
          border: true,
          height: '100%'
        },
        on: {
          'selection-change' (avg) {
            vm.$emit('selection-change', avg)
          }
        }
      },
      [
        vm.selection
          ? h('el-table-column', { attrs: { type: 'selection', width: 40 } })
          : h('el-table-column', { attrs: { type: 'index', width: 30 } }),
        ...vm.tableHeader.map((item, i) =>
          h('el-table-column', {
            attrs: mRender.getColumnAttrs(item),
            scopedSlots: {
              header (scope) {
                return h('div', item.label)
              },
              default (props) {
                return mRender.callRenderCell(props, item)
              }
            }
          })
        )
      ]
    )
  },
  data () {
    return {
      isActivated: true
    }
  },
  props: {
    selection: {
      type: Boolean,
      default: true
    },
    tableData: {
      type: Array,
      default () {
        return []
      }
    },
    tableHeader: {
      type: Array,
      default () {
        return []
      }
    }
  },
  methods: {
    onTableCellBtnClick
  },
  activated () {
    this.isActivated = true
  },
  deactivated () {
    this.isActivated = false
  }
}
</script>
<style lang="scss">
.table-wrap {
  font-size: 13px;
  .xa-img--cell {
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 24px;
    vertical-align: middle;
  }

  .xa-popover__targer {
    cursor: pointer;
    font-weight: bold;
    color: #20a0ff;
  }

  .xa-cell--nowarp {
    word-break: break-all;
    white-space: nowrap;
  }

  .xa-cell--img {
    display: inline-block;
    width: 100px;
    height: 100px;
    margin: 2px 4px;
  }

  .xa-cell--imgs {
    font-size: 0;
    line-height: 1;
    text-align: center;
  }

  .xa-cell--imgs .xa-cell--text {
    display: inline-block;
  }
  .xa-cell--icon {
    display: inline-block;
    line-height: 28px;
  }
  .xa-cell--icon .iconfont {
    position: relative;
    top: 4px;
    margin: 0 4px;
    font-size: 32px;
  }
  &.el-table {
    font-size: 12px;
    td,
    th {
      padding: 4px 0;
    }
    .cell {
      padding: 0px 4px;
    }
  }
}
</style>
<style scoped>
@keyframes fadeDisplay {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
