<template>
  <el-carousel
    :height="120 + 'px'"
    :indicator-position="indicatorPosition"
    :type="type"
  >
    <el-carousel-item
      v-for="(item, index) in imgs"
      :key="index"
      :label="item.title"
    >
      <div
        class="img-container"
        @click="onClick(item)"
        :style="{ backgroundImage: 'url(' + item.thumbUrl + ')' }"
      ></div>
    </el-carousel-item>
  </el-carousel>
</template>
<script>
export default {
  name: 'ImgCarousel',
  props: {
    height: {
      type: Number,
      default: 120
    },
    imgs: {
      type: Array,
      default() {
        return []
      }
    },
    type: {
      type: String,
      default: ''
    }
  },
  computed: {
    indicatorPosition() {
      if (this.imgs.length === 1) {
        return 'none'
      } else {
        return 'outside'
      }
    }
  },
  methods: {
    onClick(imgSrc) {
      this.$preViewImg({
        imgSrc: imgSrc.url,
        imgList: this.imgs.map(img => img.url)
      })
    }
  }
}
</script>
<style scoped>
.img-wrap {
  text-align: center;
}
.img-container {
  margin: 4px 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-clip: content-box;
}
</style>
