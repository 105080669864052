import QrCode from '@/components/AppQrcode.vue'
import ImgCarousel from '@/components/xa-table/ImgCarousel.vue'
import { getStaticImageUrl } from '@/controllers/tianditu'
const MinColumnTypeWidth = {
  QR: 100,
  IMGS: 150,
  MAP: 160
}
export default class Render {
  constructor(vm, h) {
    this.vm = vm
    this.h = h
  }
  _getColumnType(col) {
    if (col.POPOVER && col.POPOVER.length > 0) {
      return 'POPOVER'
    } else if (col.IMGS) {
      return 'IMGS'
    } else if (col.QR) {
      return 'QR'
    } else if (col.MAP) {
      return 'MAP'
    }
  }
  getColumnAttrs(column) {
    return {
      label: column.label,
      width: column.width || MinColumnTypeWidth[this._getColumnType(column)],
      resizable: column.resizable ? '' : undefined,
      'class-name':'xa_table_column_' + column.label
    }
  }
  callRenderCell(props, headItem) {
    if (headItem.POPOVER && headItem.POPOVER.length > 0) {
      return this.renderPopover(props, headItem)
    } else if (headItem.BTNS && headItem.BTNS.length > 0) {
      return this.renderBtns(props, headItem)
    } else if (headItem.IMGS) {
      return this.renderImgs(props, headItem)
    } else if (headItem.QR) {
      return this.renderQr(props, headItem)
    } else if (headItem.MAP) {
      return this.renderMapImage(props, headItem)
    } else {
      return this.renderText(props, headItem)
    }
  }
  getRowValueByKey(props, headItem) {
    return props.row[headItem.key]
  }
  renderMapImage(props, headItem) {
    const lat = this.getRowValueByKey(props, { key: headItem.lat })
    const lng = this.getRowValueByKey(props, { key: headItem.lng })
    if (lat && lng) {
      return this.h('img', {
        class: 'xa-cell',
        attrs: {
          width: 160,
          height: 100,
          src: getStaticImageUrl({
            lat,
            lng,
            width: 160,
            height: 100,
            zoom: 8,
            markers: [{ lat, lng }]
          })
        }
      })
    } else {
      return this.h('span', '-')
    }
  }
  renderPopover(props, headItem) {
    const popoverKeys = Array.isArray(headItem.POPOVER)
      ? headItem.POPOVER
      : [headItem.POPOVER]
    return this.h('div', [
      this.h(
        'el-popover',
        {
          attrs: {
            trigger: 'hover',
            placement: 'top'
          },
          scopedSlots: {
            default: () => popoverKeys.map(key => this.h('p', props.row[key]))
          }
        },
        [
          this.h(
            'span',
            {
              slot: 'reference',
              class: {
                'xa-popover__targer': true,
                'xa-cell--text': true
              }
            },
            this.getRowValueByKey(props, headItem)
          )
        ]
      )
    ])
  }
  renderQr(props, headItem) {
    const content = this.getRowValueByKey(props, headItem)
    return this.h('div', {}, [
      this.h(QrCode, {
        props: {
          content,
          size: 80
        }
      })
    ])
  }
  renderBtns(props, headItem) {
    const btns = headItem.BTNS // 所有的操作按钮 {type,label}
    let needBtn = btns.filter(btn => {
      if (btn.key === undefined) return true // 不需要关联数据的按钮
      return props.row[btn.key] // 关联数据的安安
    })
    let moreBtns = []
    if (needBtn.length >= 4) {
      // 如果按钮太多，则把后面的按钮收起到更多里面
      let temp = needBtn.splice(0, 2)
      moreBtns = needBtn
      needBtn = temp
    }
    return this.h(
      'div',
      {
        class: 'xa-cell--nowarp'
      },
      [
        // 渲染需要的按钮
        needBtn.map(btn =>
          this.h(
            'el-button',
            {
              attrs: { size: 'small', type: 'text' },
              class: 'xa-color-' + (btn.type || 'info'),
              on: {
                click: () => {
                  this.vm.onTableCellBtnClick(btn, props.row)
                }
              }
            },
            btn.label
          )
        ),
        // 渲染被收起来的按钮
        moreBtns.length > 0
          ? this.h(
              'el-dropdown',
              {
                size: 'small',
                class: 'xa-color-info',
                style: { marginLeft: '10px', fontSize: '12px' }
              },
              [
                this.h('span', { class: 'xa-color-info' }, [
                  '更多',
                  this.h('i', { class: 'el-icon-caret-bottom el-icon--right' })
                ]),
                this.h(
                  'el-dropdown-menu',
                  { slot: 'dropdown' },
                  moreBtns.map(btn => {
                    return this.h(
                      'el-dropdown-item',
                      {
                        class: 'xa-color-' + (btn.type || 'info'),
                        nativeOn: {
                          click: () => {
                            this.vm.onTableCellBtnClick(btn, props.row)
                          }
                        }
                      },
                      btn.label
                    )
                  })
                )
              ]
            )
          : ''
      ]
    )
  }
  renderText(props, headItem) {
    const text = props.row[headItem.key] // [String/Array]: 渲染的文字。如果是数组，则渲染成对应的el-tag
    const color = headItem.COLOR ? props.row[headItem.COLOR] : '' // 指定文字颜色
    return this.h(
      'div',
      {
        class: { 'xa-cell': !!headItem.ICON },
        style: {
          color
        }
      },
      [
        headItem.ICON
          ? this.h('i', {
              class: 'iconfont ' + props.row[headItem.ICON],
              style: 'margin:4px;'
            })
          : '',
        Array.isArray(text)
          ? text.map(text =>
              this.h(
                'el-tag',
                { class: 'xa-txt-12', style: 'margin:4px;' },
                text
              )
            )
          : text
      ]
    )
  }
  /**
   * 配置图片 IMGS=['img','img1','img2']
   * // 直接是图片地址
   * {img:"http://abcdefg.png"}
   * // 图片地址数组
   * {img1:["http://abcdefg.png","http://abcdefg2.png"]}
   * // 包含缩略图
   * {img2:{ "url": "http://7xlyy2.com1.z0.glb.clouddn.com/v3/user/avatar/7fe274c7ea5816d649901885581c0cc3.jpg?e=1783000163&token=kL3qRYaFy4Ip8uZVbJyYE1KL6GgwtdNbqwu5C4lO:2-BEL_HQ9msnsgpEnAVB0KTuV6c=","thumb_url": "http://7xlyy2.com1.z0.glb.clouddn.com/v3/user/avatar/7fe274c7ea5816d649901885581c0cc3.jpg?e=1783000163&token=kL3qRYaFy4Ip8uZVbJyYE1KL6GgwtdNbqwu5C4lO:2-BEL_HQ9msnsgpEnAVB0KTuV6c="}}
   * {img2:[{ "url": "http://7xlyy2.com1.z0.glb.clouddn.com/v3/user/avatar/7fe274c7ea5816d649901885581c0cc3.jpg?e=1783000163&token=kL3qRYaFy4Ip8uZVbJyYE1KL6GgwtdNbqwu5C4lO:2-BEL_HQ9msnsgpEnAVB0KTuV6c=","thumb_url": "http://7xlyy2.com1.z0.glb.clouddn.com/v3/user/avatar/7fe274c7ea5816d649901885581c0cc3.jpg?e=1783000163&token=kL3qRYaFy4Ip8uZVbJyYE1KL6GgwtdNbqwu5C4lO:2-BEL_HQ9msnsgpEnAVB0KTuV6c="}]}
   */
  renderImgs(props, headItem) {
    let imgKeys = headItem['IMGS']
    var imgsStrs = []
    function getImgsStrs(arr, item) {
      arr.push({
        thumbUrl: item.thumb_url || item,
        url: item.url || item
      })
    }
    imgKeys = Array.isArray(imgKeys) ? imgKeys : [imgKeys]
    imgKeys.forEach(key => {
      let rowData = props.row[key]
      if (!rowData) return
      if (Array.isArray(rowData)) {
        rowData.forEach(img => getImgsStrs(imgsStrs, img))
      } else {
        getImgsStrs(imgsStrs, rowData)
      }
    })
    if (imgsStrs.length > 0) {
      return this.h(ImgCarousel, {
        props: {
          imgs: imgsStrs
        }
      })
    }
  }
}
