const tiandituKey = '11cf108604f997e0a939c20e0d92ab54'

export default function(type) {
  let mapType = ''
  switch (type) {
    case '普通地图':
      mapType = 'vec_w'
      break
    case '矢量标记':
      mapType = 'cva_w'
      break
    case '影像底图':
      mapType = 'img_w'
      break
    case '影像注记':
      mapType = 'cia_w'
      break
    case '地形晕渲':
      mapType = 'ter_w'
      break
    case '地形注记':
      mapType = 'cta_w'
      break
    default:
      break
  }
  return `//t{s}.tianditu.com/DataServer?T=${mapType}&x={x}&y={y}&l={z}&tk=${tiandituKey}`
}

const StaticImageUrl = '//api.tianditu.gov.cn/staticimage'
// 静态图API
export function getStaticImageUrl({ lng, lat, width, height, markers, zoom, paths, layers, pixLocation }) {
  let querys = []
  if (lng && lat) querys.push(`center=${lng},${lat}`)
  if (width && width !== 400) querys.push(`width=${width}`)
  if (height && height !== 300) querys.push(`height=${height}`)
  if (Array.isArray(markers)) {
    querys.push(`markers=${markers.map(marker => {
      return `${marker.lng},${marker.lat}`
    }).join('|')}`)
  }
  if (zoom) querys.push(`zoom=${zoom}`)
  if (paths) querys.push(`paths=${paths}`)
  if (layers) querys.push(`layers=${layers}`)
  if (pixLocation) querys.push(`pixLocation=${pixLocation}`)
  querys.push(`tk=${tiandituKey}`)
  return StaticImageUrl + '?' + querys.join('&')
}