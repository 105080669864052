import getUrlTemplate from '@/utils/urlTemplate.js'
/**
 * @description 用在 src/components/table/table.vue
 *
 * @param {*} btnConfig
 * @param {*} rowData
 btnConfig = {
                "label": <>String>"按钮文字",
                "confirm": <String>"操作提醒",
                "action": [ajax|alert|link|link#new]"一步操作|弹窗|跳转链接|跳转链接(用一个新的tab)",
                [action=ajax]&&"batch": <Boolean>"是否是批量操作",
                "type": [success|warning|danger|info]"按钮的颜色主题",
                [action=ajax]&&"api":<String>"请求地址",
                {
                  tmp:'', 指定跳转地址的url地址，但是是模板字符串："abc/{id}/{guid}",会从params提取值进行替换参数
                  link:'', 指定要跳转的url地址，会和params组成 link+[?/&]+params=JSON.stringify(params)
                  tmp字段优选于link字段
                }
                "params":<Array> <String>[key] "ajax时候带给后后端的参数列表|alert时候传递给其他组件的参数列表|link,link#new跳转链接附带的参数列表"
            }
 */

/**
 * @description 从多个列数据 rowDatas,抽取它们的key值
 * @param {Array<Object>} rowDatas 多行列数据
 * @param {Array} paramKeys 要抽取的key值
 * @returns {} {key1:[],key2:[]}
 */
export function extractParams(rowDatas, paramKeys) {
  const params = {}
  rowDatas.forEach(row => {
    paramKeys.forEach(key => {
      if (params[key] === undefined) params[key] = []
      if (row[key] !== undefined) params[key].push(row[key])
    })
  })
  return params
}
/**
 * 显示弹窗提醒，并在异步请求时，展示loading
 * @param {*} message
 */
export function showConfirmMsg(message) {
  return new Promise(resolve =>
    this.$msgbox({
      title: '消息',
      message,
      showCancelButton: true,
      confirmButtonText: this.$t('Confirm'),
      cancelButtonText: this.$t('Cancel'),
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true
          instance.confirmButtonText = '执行中...'
          resolve(result => {
            instance.confirmButtonLoading = false
            instance.confirmButtonText = '确定'
            done()
            if (result)
              this.$message({
                type: 'success',
                message: '操作成功！'
              })
          })
        } else {
          done()
        }
      }
    })
  )
}

export async function handAjaxBtn(api, params, message) {
  const msgHandler = await showConfirmMsg.call(this, message)
  this.$diyAction(api, params, 'post')
    .then(() => {
      msgHandler(true)
      this.$emit('table', {
        action: 'refresh'
      })
    })
    .catch(error => {
      msgHandler(false)
      this.$message({
        type: 'error',
        message: error.message
      })
    })
}

/**
 * @description 这是ajax按钮的情况
 const deleteBtn = {
  label: '删除',
  confirm: '确定要删除该记录吗? ',
  action: 'ajax',
  type: 'danger',
  api: '/main/dev/map/delete',
  params: ['user_guid']
}
 */
export default async function(btnConfig, rowData) {
  const { action } = btnConfig
  let params = {}
  if (btnConfig.params) {
    // 从rowData里面提取变量
    btnConfig.params.forEach(param => (params[param] = rowData[param]))
  }
  if (action === 'ajax') {
    const { api, confirm } = btnConfig
    const message = confirm || '确定提交？'
    handAjaxBtn.call(this, api, params, message)
  } else if (action === 'alert') {
    const router = {
      name: btnConfig.router.name,
      query: btnConfig.router.query,
      params: btnConfig.router.params || {}
    }
    Object.assign(router.params, params)
    // 目前不会用到
    this.$emit('table', {
      action: 'alert',
      router
    })
  } else if (action === 'link' || action === 'link#new') {
    if (btnConfig.name) {
      const matched = this.$router.getMatchedComponents({
        name: btnConfig.name
      })
      if (matched.length > 0) {
        this.$router.push({
          naem: btnConfig.name,
          params
        })
        return
      }
    }
    if (btnConfig.tmp) {
      const url = getUrlTemplate(btnConfig.tmp, params)
      this.$gotoUrl(url, action === 'link#new')
    } else if (btnConfig.link) {
      let mParams = JSON.stringify(params)
      mParams =
        mParams.length > 2
          ? (btnConfig.link.indexOf('?') > -1 ? '&' : '?') +
            'params=' +
            window.encodeURIComponent(mParams)
          : ''
      this.$gotoUrl(btnConfig.link + mParams, action === 'link#new')
    }
  }
}
