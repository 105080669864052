const URL_PATTERN = /\{ *([\w_]+) *\}/g

export default function (urlTemplate, data) {
    return urlTemplate.replace(URL_PATTERN, function (str, key) {
        let value = data[key];

        if (value === undefined) {
            throw new Error('No value provided for variable ' + str);

        } else if (typeof value === 'function') {
            value = value(data);
        }
        return value;
    });
}